import React from "react";
import { Link } from "react-router-dom";
import Flickity from "react-flickity-component";

import "../styles/flickity.css";

const flickityOptions = {
  initialIndex: 0,
  groupCells: true,
  autoPlay: true,
  prevNextButtons: false,
  draggable: true,
  pauseAutoPlayOnHover: false,
  wrapAround: true,
};

const Seventh = () => {
  return (
    <Flickity
      className={"carousel"}
      elementType={"div"}
      options={flickityOptions}
      disableImagesLoaded={false}
      reloadOnUpdate
      static
    >
      <div className="event-carousel">
        <Link to="/events-media">
          <img className="flic-img" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/Events_at_Image-1.png"} alt="Events1" />
        </Link>
      </div>
      <div className="event-carousel">
        <Link to="/events-media">
          <img className="flic-img" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/Indnew1.png"} alt="Events3" />
        </Link>
      </div>
      <div className="event-carousel">
        <Link to="/events-media">
          <img className="flic-img" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/Events_at_Image-4.png"} alt="Events4" />
        </Link>
      </div>
    </Flickity>
  );
};

export default Seventh;
